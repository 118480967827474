import React, { useEffect, useState } from 'react';
import { Button } from 'baseui/button';
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
const Login = () => {
  // const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [pass, setPass] = useState(null);
  // const navigate = useNavigate();

  // function login() {
  //   checkLogin()
  // }

  // async function checkLogin() {
  //   setLoading(true)
  //   let user = await CheckUser(email)
  //   setLoading(false)
  //   if (!user) {
  //     alert('Email Not found !!');
  //   } else if (user.password !== pass) {
  //     alert('Email or Password is incorrect !!');
  //   } else {
  //     setStorage("eme00-966311", email);
  //     navigate("/main");
  //   }
  // }

  // async function loginGoogle(data) {
  //   console.log('user', data);
  //   setLoading(true)
  //   let user = await CheckUser(data.email)
  //   if (!user) {
  //     await RegisterOnFirebase(data.email, data.name, data.exp)
  //   }
  //   setLoading(false)
  //   setStorage("eme00-966311", data.email);
  //   navigate("/main");
  // }

  // const responseMessage = (response) => {
  //   // console.log('response', response);
  //   const token = response.credential;
  //   const user = jwt(token);
  //   loginGoogle(user)
  // };
  // const errorMessage = (error) => {
  //   console.log(error);
  //   alert(error);
  // };

  return (
    <>
      <FormControl
        label={() => "Email"}
      >
        <Input
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
          required
        />
      </FormControl>
      <FormControl
        label={() => "Password"}
      >
        <Input
          onChange={event => setPass(event.currentTarget.value)}
          type="password"
          value={pass}
        />
      </FormControl>
      <Button onClick={() => alert('thank you 🙂')}>Login</Button>
    </>
  );
};

export default Login;