import React, { useEffect, useState } from 'react';
import { Button } from 'baseui/button';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
} from 'baseui/modal';
import { getStorage } from "../state/localStorage.js";
import { LOGIN_STATE } from "../state/keys.js";
import Login from '../components/form/login.js';
import Register from '../components/form/register.js';
import { Card, StyledBody, StyledAction } from 'baseui/card';
import * as loadingData from "../assets/loading/52601-waiting.json";
import * as loadingData2 from "../assets/loading/23398-waiting.json";
import Lottie from "react-lottie";
import { Heading, HeadingLevel } from 'baseui/heading';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: loadingData2.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

const Main = () => {
    const [modalShow, setModalShow] = useState(false);
    const [isRegister, setIsRegister] = useState(false);

    useEffect(() => {
        if (!getStorage(LOGIN_STATE)) {
            setModalShow(true);
        }
    }, [])

    function close() {
        setModalShow(false);
    }

    return (
        <>
            <Lottie options={defaultOptions2} height={'100%'} width={'100%'}/>

            <Modal closeable={false} isOpen={modalShow}>
                <ModalHeader>You need to login to access this page.🙂</ModalHeader>
                <ModalBody>
                    <Lottie options={defaultOptions} height={60} width={100} />
                    {isRegister ?
                        <Register />
                        :
                        <Login />
                    }
                    <HeadingLevel>
                        <Heading styleLevel={6}>Or login with.</Heading>
                    </HeadingLevel>
                </ModalBody>
                <ModalFooter>
                    {/* <ModalButton kind="tertiary" onClick={close}>
                        Cancel
                    </ModalButton> */}
                    <ModalButton onClick={() => setIsRegister(!isRegister)}>{isRegister ? 'Back to Login' : 'Create new account'}</ModalButton>
                </ModalFooter>
            </Modal>
        </>
    );
}
export default Main;