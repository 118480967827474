import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'baseui/button';
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { RegisterOnFirebase } from '../../services/firebase';
import { setStorage } from "../../state/localStorage.js";
import { LOGIN_STATE } from "../../state/keys.js";

const Register = () => {
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [emailErr, setEmailErr] = useState(false);
  const [emailCap, setEmailCap] = useState(null);
  const [pass1, setPass1] = useState(null);
  const [pass1Err, setPass1Err] = useState(false);
  const [pass1Cap, setPass1Cap] = useState(null);
  const [pass2, setPass2] = useState(null);
  const [pass2Err, setPass2Err] = useState(false);
  const [pass2Cap, setPass2Cap] = useState(null);
  const navigate = useNavigate();

  const onRegister = async (e) => {
    e.preventDefault()
    setLoading(true)

    setEmailErr(false)
    setEmailCap(null)
    setPass1Err(false)
    setPass1Cap(null)
    setPass2Err(false)
    setPass2Cap(null)
    console.log(email, pass1, pass2);
    if (!email || !pass1 || !pass2) {
      setEmailErr(true)
      if (!email) {
        setEmailCap('Please fill your email.')
      }
      setPass1Err(true)
      if (!pass1) {
        setPass1Cap('Please fill Password.')
      }
      setPass2Err(true)
      if (!pass2) {
        setPass2Cap('Please fill Comfirm Password.')
      }
    } else if (pass1?.length < 8) {
      setPass1Err(true)
      setPass1Cap('Password must be at least 8 characters.')
    } else if (pass1 != pass2) {
      setPass1Err(true)
      setPass1Cap('Passwords do NOT match.')
      setPass2Err(true)
      setPass2Cap('Passwords do NOT match.')
    } else {
      const user = await RegisterOnFirebase(email, pass1)
      console.log('user',user);
      if (!user) {
        setEmailErr(true)
        setEmailCap('Invalid email.')
      } else {
        setStorage(LOGIN_STATE, user);
        navigate("/");
      }
    }
    setLoading(false)
  }

  return (
    <>
      <FormControl
        label={() => "Email"}
        caption={() => emailCap}
        error={emailErr}
      >
        <Input
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
          autoFocus
          required
        />
      </FormControl>
      <FormControl
        label={() => "Password"}
        caption={() => pass1Cap}
        error={pass1Err}
      >
        <Input
          onChange={(event) => {
            setPass1(event.currentTarget.value)
            if (event.currentTarget.value?.length < 8) {
              setPass1Err(true)
              setPass1Cap('Password must be at least 8 characters.')
            } else {
              setPass1Err(false)
              setPass1Cap(null)
            }
          }
          }
          type="password"
          value={pass1}
        />
      </FormControl>
      <FormControl
        label={() => "Comfirm Password"}
        caption={() => pass2Cap}
        error={pass2Err}
      >
        <Input
          onChange={(event) => {
            setPass2(event.currentTarget.value)
            if (pass1 != event.currentTarget.value) {
              setPass2Err(true)
              setPass2Cap('Passwords do NOT match.')
            } else {
              setPass2Err(false)
              setPass2Cap(null)
            }
          }
          }
          type="password"
          value={pass2}
        />
      </FormControl>
      <Button onClick={onRegister}>Register</Button>
    </>
  );
};

export default Register;