import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCCkXzKqAW9Vwq8hMMiVBS2fQk0XGtFXqo",
  authDomain: "hob-notes.firebaseapp.com",
  projectId: "hob-notes",
  storageBucket: "hob-notes.appspot.com",
  messagingSenderId: "1043319084294",
  appId: "1:1043319084294:web:4de34f9c6e1b2e092bba87",
  measurementId: "G-WTLHRPP1Y3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export const RegisterOnFirebase = async (email, pass) => {
  const user = await createUserWithEmailAndPassword(auth, email, pass)
    .then((userCredential) => {
      // Signed in 
      console.log('mmm', userCredential);
      return userCredential.user
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      return null
    });
  return user;
}