import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './layouts/main'

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/main/article" element={<ArticlePage />} />
        <Route path="/main/story" element={<StoryPage />} /> */}
        {/* <Route path="/main/story" element={<MakeStoryPage />} />
        <Route path="/main/profile" element={<ProfilePage />} />
        <Route path="/main" element={<MainPage />} />*/}
        <Route path="/" element={<Main />} /> 
      </Routes>
    </Router>
  );
}

export default App;